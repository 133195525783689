import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const TOP_BANNER_BG_TYPES = {
  warning: 'bg-gradient-to-r from-warning-500 to-warning-300',
  danger: 'bg-gradient-to-r from-danger-500 to-danger-300',
  info: 'bg-neon',
};

const TOP_BANNER_TEXT_TYPES = {
  warning: 'text-warning-900 font-regular',
  danger: 'text-danger-900 font-regular',
  info: 'text-neutral-500 font-regular',
};

const TOP_BANNER_TEXT_HOVER_TYPES = {
  warning: 'hover:text-warning-800',
  danger: 'hover:text-danger-800',
};

export const TopBanner = ({
  message,
  type = 'info',
  linkTo = '',
  linkLabel = '',
  centered = false,
  hidden = false,
}) => {
  const navigate = useNavigate();
  const bgClassName = TOP_BANNER_BG_TYPES[type];
  const textClassName = TOP_BANNER_TEXT_TYPES[type];
  const hoverClassName = TOP_BANNER_TEXT_HOVER_TYPES[type];
  const sectionClassName = `py-2 px-4 ${bgClassName} ${
    !!linkTo ? 'cursor-pointer' : ''
  }`;

  const hasLinkAndLabel = linkTo && linkLabel;
  const isExternalLink = linkTo.startsWith('http');

  const onClickSection = () => {
    if (isExternalLink) {
      window.open(linkTo, '_blank');
    } else {
      navigate(linkTo);
    }
  };

  if (hidden) {
    return null;
  }

  return (
    <section
      data-id="main-alert"
      className={sectionClassName}
      {...(linkTo && { onClick: onClickSection })}
    >
      <div
        className={`container mx-auto flex flex-col gap-2 sm:flex-row md:items-center ${
          centered ? 'sm:justify-center' : ''
        } ${!centered ? 'sm:gap-6' : ''}`}
      >
        <span
          className={`text-small ${textClassName} ${!centered ? 'grow' : ''}`}
        >
          {message}
        </span>

        {hasLinkAndLabel && (
          <Link
            overrideStyles
            className={`text-small font-regular flex shrink-0 grow-0 items-center gap-2 ${textClassName} ${hoverClassName}`}
            to={linkTo}
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span>{linkLabel}</span>
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        )}
      </div>
    </section>
  );
};
